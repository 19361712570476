/** @format */

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Counter() {
  return (
    <>
      <section
        style={{
          backgroundColor: "#482f30",
          color: "white",
          padding: "50px 0",
          marginTop: "70px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Our Impact in Numbers</h2>
        <h6 style={{ textAlign: "center", color: "#f6ab4a" }}>
          Join Thousands of Happy Pet Parents
        </h6>
        <p style={{ textAlign: "center" }}>
          Every adoption makes a difference. Here's a look at the lives we’ve
          touched and the milestones we’ve achieved together.
        </p>
        <Container style={{ paddingTop: "30px" }}>
          <Row>
            <Col
              md={3}
              style={{
                borderRight: "1px solid white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <h1 style={{ textAlign: "center" }}>1,500+</h1>
                <p>Happy Adoptions</p>
              </div>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
                borderRight: "1px solid white",
              }}
            >
              <div>
                <h1 style={{ textAlign: "center" }}>120</h1>
                <p>Pets Available</p>
              </div>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
                borderRight: "1px solid white",
              }}
            >
              <div>
                <h1 style={{ textAlign: "center" }}>10</h1>
                <p>Years of Service</p>
              </div>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
                borderRight: "1px solid white",
              }}
            >
              <div>
                <h1 style={{ textAlign: "center" }}>1,200+</h1>
                <p>Happy Pet Parents</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
