/** @format */

import { Button } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import catdogimg from "../../Images/dogcatgroup.png";
import { Link } from "react-router-dom";
export default function NextSection() {
  return (
    <>
      <section style={{ marginTop: "70px" }}>
        <Container>
          <Row>
            <Col md={6}>
              <h5
                style={{
                  color: "#f6ab4a",
                  fontSize: "30px",
                  fontWeight: "500",
                }}
              >
                Meet Our Adorable Pets
              </h5>
              <h1 style={{ color: "#482f30", fontWeight: "700" }}>
                Find the Perfect Companion for Your Home
              </h1>
              <p
                style={{
                  fontSize: "18px",
                  color: "#898989",
                  fontWeight: "400",
                }}
              >
                Discover loving cats, dogs, and more waiting for a forever home.
                Every pet is unique, and we’ll help you find the perfect match.
              </p>
              <p style={{ color: "#898989", fontWeight: "400" }}>
                Each pet has their own unique personality, story, and needs.
                Whether you’re looking for a playful puppy, a gentle cat, or a
                small pet, we have a variety of animals waiting for a loving
                home. Our adoption process ensures that you and your future pet
                are the perfect match. We provide all the necessary health
                check-ups, vaccinations, and support to make sure your new
                companion is ready for their forever home. Take a look at our
                available pets and give them the second chance they deserve!
              </p>
              <Link to="/mypet">
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#f6ab4a", borderRadius: "12px" }}
                >
                  My Pet
                </Button>
              </Link>
            </Col>
            <Col md={6}>
              <img src={catdogimg} alt="" width="100%" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
