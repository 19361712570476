/** @format */

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Spinner, Alert } from "react-bootstrap";
import { getApihandler } from "../../Apihandler";

export default function GetVideo() {
  const [videos, setVideos] = useState([]); // Initialize as an empty array
  console.log("videos is---->", videos);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    const res = await getApihandler("/getAllVideos");
    console.log("get video api response is ", res); // Log the entire response for debugging
    setVideos(res.videos);
  };

  return (
    <Container style={{ marginTop: "70px" }}>
      <h2 style={{ textAlign: "center" }}>Pet Care Made Simple</h2>
      <h6 style={{ textAlign: "center" }}>
        Access Video Tutorials and Training Guides
      </h6>
      <p
        style={{
          color: "#f6ab4a",
          marginBottom: "50px",
          textAlign: "center",
        }}
      >
        We believe that adopting a pet is just the beginning of a beautiful
        journey. To help you take the best care of your new furry friend, we
        offer a wide range of video tutorials and training guides. From basic
        obedience training to grooming tips, these videos are designed to
        support you every step of the way. Whether you're a first-time pet
        parent or looking to brush up on your skills, our easy-to-follow videos
        make pet care simple and accessible for everyone.
      </p>
      <Row>
        {videos.map((video) => (
          <Col md={4} key={video._id} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{video.title}</Card.Title>
                <Card.Text>{video.description}</Card.Text>
                {/* Display the video using a video tag */}
                <video width="100%" controls>
                  <source
                    src={"http://localhost:80/" + video.video}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
