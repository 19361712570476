/** @format */

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
export default function Footer() {
  return (
    <>
      <footer
        style={{
          backgroundColor: " #482F30",
          marginTop: "20px",
          padding: "30px",
          color: "white",
        }}
      >
        <Container>
          <Row>
            <Col md={3}>
              <h1 style={{ color: "white" }}>LOGO</h1>
              <p style={{ color: "white" }}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered{" "}
              </p>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Enter Email"
                  style={{ padding: "7px" }}
                />
              </div>

              <FacebookIcon
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#F6AB4A",
                  borderRadius: "8px",
                }}
              />
              <TwitterIcon
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#F6AB4A",
                  borderRadius: "8px",
                }}
              />
              <InstagramIcon
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#F6AB4A",
                  borderRadius: "8px",
                }}
              />
              <YouTubeIcon
                sx={{
                  marginRight: "10px",
                  backgroundColor: "#F6AB4A",
                  borderRadius: "8px",
                }}
              />
            </Col>
            <Col md={3}>
              <h4>Quick Links</h4>
              <ul style={{ paddingLeft: "0rem" }}>
                <a
                  href="/home"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <li style={{ listStyle: "none" }}>Home</li>
                </a>
                <a
                  href="/aboutus"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <li style={{ listStyle: "none" }}>About us</li>
                </a>
                <a
                  href="/adoptionlist"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <li style={{ listStyle: "none" }}>Adoption List</li>
                </a>
                <a
                  href="/contact"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <li style={{ listStyle: "none" }}>Contact Us</li>
                </a>
              </ul>
            </Col>
            {/* <Col md={3}>
              <h4>Explore</h4>
              <ul style={{ paddingLeft: "0rem" }}>
                <li style={{ listStyle: "none" }}>Lorem, ipsum.</li>
                <li style={{ listStyle: "none" }}>lorem ,ipsum.</li>
                <li style={{ listStyle: "none" }}>lorem ,ipsum.</li>
                <li style={{ listStyle: "none" }}>lorem ,ipsum.</li>
                <li style={{ listStyle: "none" }}>lorem ,ipsum.</li>
              </ul>
            </Col> */}
            <Col md={3}>
              <h4>Get InTouch</h4>
              <ul style={{ paddingLeft: "0rem" }}>
                <li
                  style={{
                    listStyle: "none",
                    color: "white",
                    marginBottom: "15px",
                  }}
                >
                  <LocationOnIcon />
                  85 ketch Harbour Road Bansal 190020
                </li>
                <li style={{ listStyle: "none", marginBottom: "15px" }}>
                  <CallIcon />
                  +01257211878
                </li>
                <li style={{ listStyle: "none", marginBottom: "15px" }}>
                  <MailIcon />
                  Admin@gmail.com
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
