/** @format */

import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../Components/Header";
import { useForm } from "react-hook-form";
import { postApihandler, PostRequestFormControl } from "../../Apihandler";
import Swal from "sweetalert2";
export default function AddPetProfile() {
  const { register, handleSubmit, reset } = useForm();
  const [images, setImages] = React.useState("");
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImages(file);
    }
  };
  const onSubmit = async (value) => {
    const userId = localStorage.getItem("userId");
    const formData = new FormData();

    const {
      name,
      species,
      breed,
      age,
      gender,
      size,
      color,
      description,
      vaccineStatus,
      adoptionFee,
      location,
      photos, // Assuming this is an array or a FileList
      email,
      phone,
    } = value;
    // console.log("value is ----->", value);

    formData.append("userId", userId);
    formData.append("name", name);
    formData.append("species", species);
    formData.append("breed", breed);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("size", size);
    formData.append("color", color);
    formData.append("description", description);
    formData.append("vaccineStatus", vaccineStatus);
    formData.append("adoptionFee", adoptionFee);
    formData.append("location", location);

    // Properly append photos one by one

    formData.append("photos", images);

    formData.append("email", email);
    formData.append("phone", phone);

    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    const res = await postApihandler("/addPetAddoptionProfile", formData);
    console.log("add pet api response is ----->", res);

    if (res.message === "Pet profile created successfully") {
      reset();
      setImages("");
      // Display success alert using Swal
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Pet profile added successfully!",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res.error.response.data.error,
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Box
          sx={{
            marginTop: "50px",
            boxShadow: " 0px 0px 2px 1px #00000040",
            padding: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              sx={{
                color: "#f6ab4a",
                textAlign: "center",
                marginBottom: "30px",
              }}
              variant="h4"
            >
              Add Pet Profile
            </Typography>
            <input
              type="text"
              placeholder="Name"
              className="form-control mb-3"
              {...register("name", { required: true })}
              // style={{ width: "400px" }}
            />
            <input
              type="text"
              placeholder="Species"
              className="form-control mb-3"
              {...register("species", { required: true })}
            />
            <input
              type="text"
              placeholder="Breed"
              className="form-control mb-3"
              {...register("breed")}
            />
            <input
              type="number"
              placeholder="Age"
              className="form-control mb-3"
              {...register("age")}
            />
            <select
              className="form-control mb-3"
              {...register("gender", { required: true })}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <input
              type="text"
              placeholder="Size (Small, Medium, Large)"
              className="form-control mb-3"
              {...register("size")}
            />
            <input
              type="text"
              placeholder="Color"
              className="form-control mb-3"
              {...register("color")}
            />
            <textarea
              placeholder="Description"
              className="form-control mb-3"
              {...register("description")}
            />
            <input
              type="text"
              placeholder="Vaccine Status"
              className="form-control mb-3"
              {...register("vaccineStatus")}
            />
            <input
              type="number"
              placeholder="Adoption Fee"
              className="form-control mb-3"
              {...register("adoptionFee")}
            />
            <input
              type="text"
              placeholder="Location"
              className="form-control mb-3"
              {...register("location")}
            />
            <input
              type="email"
              placeholder="Email"
              className="form-control mb-3"
              {...register("email")}
            />
            <input
              type="text"
              placeholder="Phone"
              className="form-control mb-3"
              {...register("phone")}
            />
            <input
              type="file"
              name="images"
              multiple
              onChange={handleImageUpload}
            />
            <div>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#f6ab4a",
                  textAlign: "left",
                  marginTop: "30px",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Container>
    </>
  );
}
