/** @format */

import React from "react";
import "../../Css/home.css";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export default function HomePage() {
  return (
    <>
      <section className="banner_section">
        <Container>
          <Row>
            <Col md={6} style={{ marginTop: "70px" }}>
              <Typography
                variant="h3"
                sx={{ color: "white", fontWeight: "700", marginBottom: "25px" }}
              >
                Find Your Forever Friend
              </Typography>
              <Typography variant="p" sx={{ color: "white" }}>
                Adopt a loving pet today and give them a home they deserve.
                Together, we can make a difference in their lives.
              </Typography>
              <div className="mt-4">
                <Link to="/petprofile">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#f6ab4a", color: "white" }}
                  >
                    Add Pet Profile
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
