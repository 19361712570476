/** @format */

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Col, Container } from "react-bootstrap";
import { getApihandler } from "../../Apihandler";
import { Card, CardContent, CardHeader } from "@mui/material"; // Update this line

export default function AdoptionList() {
  const [data, setData] = useState([]);
  console.log("booked pet data is--->", data);
  useEffect(() => {
    getBookPets();
  }, []);
  const getBookPets = async () => {
    const userId = localStorage.getItem("userId");
    const res = await getApihandler(`/getPetAdoptionByUser/${userId}`);
    setData(res);
    console.log("get booked pet profile is ---->", res);
  };
  return (
    <>
      <Header />
      <Container style={{ marginTop: "30px" }}>
        {/* <h1>Adoption List</h1> */}
        {/* Rendering the list of pets */}
        <div className="adoption-list">
          <Col md={6}>
            {data && data.length > 0 ? (
              data.map((adoption) => (
                <Card className="mb-3" key={adoption._id}>
                  {" "}
                  {/* Add a unique key */}
                  <CardContent>
                    {/* Accessing pet details from the nested petId object */}
                    <CardHeader
                      title={`${adoption.petId.name} (${adoption.petId.species})`}
                    />
                    <CardContent>
                      <strong>Breed:</strong> {adoption.petId.breed}
                      <br />
                      <strong>Age:</strong> {adoption.petId.age}
                      <br />
                      <strong>Location:</strong> {adoption.petId.location}
                      <br />
                      <strong>Adoption Fee:</strong> ₹
                      {adoption.petId.adoptionFee}
                      <br />
                      <strong>Gender:</strong> {adoption.petId.gender}
                      <br />
                      <strong>Color:</strong> {adoption.petId.color}
                      <br />
                      <strong>Status:</strong> {adoption.status}
                      <br />
                      <strong>Vaccine Status:</strong>{" "}
                      {adoption.petId.vaccineStatus}
                    </CardContent>
                    {/* Display photos */}
                    {adoption.petId.photos &&
                      adoption.petId.photos.length > 0 && (
                        <div className="photos">
                          {adoption.petId.photos.map((photo, index) => (
                            <img
                              key={index}
                              src={`http://localhost:80/${photo}`}
                              alt={`Pet ${index + 1}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                marginRight: "10px",
                              }}
                            />
                          ))}
                        </div>
                      )}
                  </CardContent>
                </Card>
              ))
            ) : (
              <p>No pets available for adoption.</p>
            )}
          </Col>
        </div>
      </Container>
      <Footer />
    </>
  );
}
