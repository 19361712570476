/** @format */

import React from "react";
import Chat from "../../Components/Chat";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
export default function UserChat() {
  return (
    <div>
      <Header />
      <Chat />
      <Footer />
    </div>
  );
}
