/** @format */

import React from "react";
import {
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { postApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const LoginSubmit = async (value) => {
    // console.log("value is --->", value);
    const res = await postApihandler("/UserLogin", value);

    
    
   
    if (res.status === 200) {
      localStorage.setItem("accessToken", res.accessToken);
      const userId = res.data._id;
    localStorage.setItem("userId", userId);
      Swal.fire({
        icon: "success",
        title: "Successfully login",
        showConfirmButton: false,
        timer: 2000,
      });
      //   reset();
      navigate("/home");
    } else {
      // console.log("Errors");
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  return (
    <>
      <section className="signup_bg" style={{ height: "100vh" }}>
        <h2 style={{ textAlign: "center", color: "white" }}>
          PET ADOPTION PLATFORM
        </h2>

        <Container>
          <Row>
            <Col md={6}></Col>
            <Col md={6} className="mt-5">
              <Form
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "20px",
                }}
                onSubmit={handleSubmit(LoginSubmit)}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "yellow",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  Do You Like Pets
                </Typography>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label style={{ color: "white" }}>
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("userEmail")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label style={{ color: "white" }}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    {...register("password")}
                  />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Sign In
                </Button>
                <p style={{ color: "white" }}>
                  Don't Have An Account{" "}
                  <Link to="/signup" style={{ textDecoration: "none" }}>
                    <span style={{ color: "#0d6efd", fontWeight: "700" }}>
                      Sign Up
                    </span>
                  </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
