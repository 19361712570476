/** @format */

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import blogcard1 from "../../Images/blog1.png";
import blogcard2 from "../../Images/blog2.png";
import blogcard3 from "../../Images/blog3.png";
export default function Blogs() {
  return (
    <>
      <section
        style={{
          marginTop: "200px",
          backgroundColor: "#482F30",
          padding: "30px",
        }}
      >
        <Container>
          <h4
            className="text-center"
            style={{
              color: "white",
              fontSize: "30px",
              fontWeight: "500",
              marginTop: "50px",
            }}
          >
            Pet Parenting Tips & Stories
          </h4>
          <h6 style={{ color: "#f6ab4a", textAlign: "center" }}>
            Stay Informed, Inspired, and Engaged with Our Blog
          </h6>
          <p style={{ color: "white", textAlign: "center" }}>
            Explore the world of pet care, heartwarming adoption stories, and
            helpful tips for first-time pet parents. Our blog offers expert
            advice and real-life stories to help you provide the best care for
            your furry friend.
          </p>
          <Row className="mt-5">
            <Col md={4}>
              <Card
                className="blog_cards"
                sx={{
                  maxWidth: 345,
                  borderRadius: "24px",
                  padding: "30px",
                  // backgroundColor: "rgb(131 109 110)",
                }}
              >
                <CardActionArea>
                  <img src={blogcard1} alt="" width="100%" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      5 Tips for First-Time Pet Parents
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Adopting a pet for the first time? Learn how to make the
                      transition smooth for both you and your new companion.
                      From pet-proofing your home to basic training tips, we’ve
                      got you covered.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    sx={{ color: "#f6ab4a" }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="blog_cards"
                sx={{
                  maxWidth: 345,
                  borderRadius: "24px",
                  padding: "30px",
                  // backgroundColor: "rgb(131 109 110)",
                }}
              >
                <CardActionArea>
                  <img src={blogcard2} alt="" width="100%" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Success Stories: How Adoption Changes Lives
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Discover inspiring stories of pets who found their forever
                      homes and the joy they brought to their new families.
                      These heartwarming tales will brighten your day.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    sx={{ color: "#f6ab4a" }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="blog_cards"
                sx={{
                  maxWidth: 345,
                  borderRadius: "24px",
                  padding: "30px",
                  // backgroundColor: "rgb(131 109 110)",
                }}
              >
                <CardActionArea>
                  <img src={blogcard3} alt="" width="100%" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      The Importance of Vaccination & Health
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Ensuring your pet’s health is a priority. Learn why
                      regular vet visits, vaccinations, and preventive care are
                      essential to keeping your pet happy and healthy.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    sx={{ color: "#f6ab4a" }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
