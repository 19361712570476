/** @format */

import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Container } from "react-bootstrap";
import { Box, Button } from "@mui/material";
import { postApihandler } from "../../Apihandler";
import Swal from "sweetalert2";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhone] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const userId = localStorage.getItem("userId");

    // Construct the data to be sent
    const data = {
      userId,
      username: name,
      title: title,
      query: query,
      email: email,
      phone: phoneno,
    };

    console.log("Form data is ---->", data);

    // Call the API
    const res = await postApihandler("/addPetEnquiry", data);
    if (res.message === "Pet enquiry submitted successfully!") {
      Swal.fire({
        icon: "success",
        title: "Pet enquiry submitted successfully!",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to add Pet enquiry",
        text: res.error.response.data.message || "Something went wrong!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-5">
        <h4 style={{ textAlign: "center" }}>Send Query</h4>
        <Box sx={{}}>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <h6>Name</h6>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <h6>Title</h6>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <h6>Query</h6>
              <textarea
                className="form-control"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <h6>Email</h6>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <h6>Phone No.</h6>
              <input
                type="tel"
                className="form-control"
                value={phoneno}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            {/* Button for submitting the form */}
            <Button
              type="submit"
              sx={{
                backgroundColor: "#b4af6a",
                color: "white",
                backgroundColor: "#f6ab4a",
              }}
              onClick={handleSubmit} // Ensure the button triggers the submit function
            >
              Send Query
            </Button>
          </form>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
