/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApihandler } from "../../Apihandler";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Card, CardContent, CardMedia } from "@mui/material";

export default function MyPetProfile() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getMypetProfile();
  }, []);
  const getMypetProfile = async () => {
    const userId = localStorage.getItem("userId");
    console.log("user id id ----->", userId);
    const res = await getApihandler(`/getPetProfileByUseId/${userId}`);
    if(res.data){
      setData(res.data);
    }
    
    console.log("my pet profile api response is ", res);
  };
  return (
    <>
      <Header />
      <Container style={{ marginTop: "30px" }}>
        <Row>
          {data.length !== 0 ?data.map((pet) => (
            <Col md={4}>
              <Card key={pet._id} sx={{ padding: "30px" }}>
                <img
                  src={"http://localhost:80/" + pet.photos[0]}
                  alt=""
                  // width="100%"
                />
                <CardContent>
                  <h2 className="text-center">{pet.name}</h2>
                  <p>Species: {pet.species}</p>
                  <p>Breed: {pet.breed}</p>
                  <p>Age: {pet.age}</p>
                  <p>Gender: {pet.gender}</p>
                  <p>Location: {pet.location}</p>
                  <p>Adoption Fee: {pet.adoptionFee}</p>
                </CardContent>
              </Card>
            </Col>
          )):<h1>No Data</h1>}
        </Row>
      </Container>

      <Footer />
    </>
  );
}
