/** @format */

import axios from "axios";

// export const serverUrl = "http://3.19.214.110/api";
export const serverUrl = "http://petadoption.us/api";
// export const serverUrl = "http://192.168.1.5:80/api";

// export const imageUrl = "https://gharaajs3.s3.us-east-2.amazonaws.com/";

export const getApihandler = async (endPoint) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const getres = await axios.get(serverUrl + endPoint, {
      headers: {
        "x-access-token": accessToken,
      },
    });

    return getres.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};

export const postApihandler = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");

  try {
    const postRes = await axios.post(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    // console.log("apipost=>",  postRes);
    return postRes.data;
  } catch (error) {
    // console.log("error is - ", error.response);
    return { error };
  }
};
export const postWithoutAccessApihandler = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");

  try {
    const postRes = await axios.post(serverUrl + endPoint, value);
    // console.log("apipost=>",  postRes);
    return postRes.data;
  } catch (error) {
    // console.log("error is - ", error.response);
    return { error };
  }
};
export const PostRequestFormControl = async (endPoint, requestBody) => {
  console.log("endPoint", endPoint);
  console.log("requestBody", requestBody);
  try {
    let assessToken = localStorage.getItem("accessToken");
    let result = await fetch(serverUrl + endPoint, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": assessToken,
      },
      processData: false,
      contentType: false,
      body: requestBody,
    });
    let response = await result.json();
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const putApihandler = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const res = await axios.put(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    return res.data;
  } catch (error) {
    return { error };
  }
};

export const postApihandlerAccessToken = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const res = await axios.post(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    return res.data;
  } catch (error) {
    return { error };
  }
};

export const deleteApiihandler = async (endPoint, id) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const deleteRes = await axios.delete(serverUrl + endPoint + id, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    // console.log("apidelete=>", deleteRes);
    return deleteRes.data;
  } catch (error) {
    return { message: error.message, status: 403 };
  }
};

export const deleteParamsApiihandler = async (endPoint) => {
  // console.log("endPoint==>", endPoint);
  let accessToken = localStorage.getItem("accessToken");
  try {
    const deleteRes = await axios.delete(
      serverUrl + endPoint
      //   , {
      //   headers: {
      //     "x-access-token": accessToken,
      //   },
      // }
    );
    // console.log("apidelete=>", deleteRes);
    return deleteRes.data;
  } catch (error) {
    return { error };
  }
};
