/** @format */

import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import NextSection from "../../Components/HomePage/nextsection";
import ChooseUs from "../../Components/HomePage/chooseus";
import Counter from "../../Components/HomePage/counter";
import { Col, Container, Row } from "react-bootstrap";
import teamimg1 from "../../Images/image.png";
import teamimg2 from "../../Images/image (1).png";
import teamimg3 from "../../Images/image (2).png";
import teamimg4 from "../../Images/image (3).png";

import { Box } from "@mui/material";
export default function AboutUs() {
  return (
    <>
      <Header />
      <section className="about_banner">
        <Container className="heading">
          <h1 style={{ color: "white", textAlign: "center" }}>About Us</h1>
          <h6 style={{ color: "white", textAlign: "center" }}>
            Passionate About Connecting Pets and People
          </h6>
          <p style={{ color: "white", textAlign: "center" }}>
            At our core, we are a community of animal lovers dedicated to giving
            pets the forever homes they deserve. Our mission is to make pet
            adoption a joyous, fulfilling experience by connecting animals in
            need with caring families. With years of experience in animal
            welfare, we focus on transparency, compassion, and support
            throughout the entire adoption process. From offering detailed pet
            profiles to providing post-adoption guidance, we’re here to ensure
            every pet and their new family start their journey on the right paw.
            Together, we’re building a brighter future—one adoption at a time.
          </p>
        </Container>
      </section>
      <NextSection />
      <ChooseUs />
      <Counter />
      <section>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <h3>OUR TEAM</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar
            elementum tempus hac tellus libero accumsan.{" "}
          </p>
        </div>
        <Container className="mt-5 mb-5">
          <Row>
            <Col md={3}>
              <Box sx={{ boxShadow: "0px 0px 2px 1px #00000040" }}>
                <div>
                  <img src={teamimg1} alt="" width="100%" />
                </div>
                <div style={{ padding: "15px" }}>
                  <h4 style={{ textAlign: "center" }}>Robert Clayton</h4>
                  <h5 style={{ textAlign: "center", color: "#F6AB4A" }}>
                    Founder
                  </h5>
                </div>
              </Box>
            </Col>
            <Col md={3}>
              <Box sx={{ boxShadow: "0px 0px 2px 1px #00000040" }}>
                <div>
                  <img src={teamimg2} alt="" width="100%" />
                </div>
                <div style={{ padding: "15px" }}>
                  <h4 style={{ textAlign: "center" }}>Robert Clayton</h4>
                  <h5 style={{ textAlign: "center", color: "#F6AB4A" }}>
                    Founder
                  </h5>
                </div>
              </Box>
            </Col>
            <Col md={3}>
              <Box sx={{ boxShadow: "0px 0px 2px 1px #00000040" }}>
                <div>
                  <img src={teamimg3} alt="" width="100%" />
                </div>
                <div style={{ padding: "15px" }}>
                  <h4 style={{ textAlign: "center" }}>Robert Clayton</h4>
                  <h5 style={{ textAlign: "center", color: "#F6AB4A" }}>
                    Founder
                  </h5>
                </div>
              </Box>
            </Col>
            <Col md={3}>
              <Box sx={{ boxShadow: "0px 0px 2px 1px #00000040" }}>
                <div>
                  <img src={teamimg4} alt="" width="100%" />
                </div>
                <div style={{ padding: "15px" }}>
                  <h4 style={{ textAlign: "center" }}>Robert Clayton</h4>
                  <h5 style={{ textAlign: "center", color: "#F6AB4A" }}>
                    Founder
                  </h5>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
