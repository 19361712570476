import "./App.css";
import Signup from "./Pages/Signup";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import MyProfile from "./Pages/Myprofile";
import AddPetProfile from "./Pages/AddPetProfile";
import PetProfile from "./Pages/PetProfile";
import MyPetProfile from "./Pages/Mypetprofile";
import FindPetFilter from "./Pages/FindPetFilter";
import AdoptionList from "./Pages/AdoptionList";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import UserChat from "./Pages/Chat";
import Room from "./Components/Room/room";
import Landing from "./Pages/Landing";

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/room/:id" element={<Room />} />
          <Route path="/home" element={<Home />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/petprofile" element={<AddPetProfile />} />
          <Route path="/pet/:petId" element={<PetProfile />} />
          <Route path="/mypet" element={<MyPetProfile />} />
          <Route path="/findpet" element={<FindPetFilter />} />
          <Route path="/adoptionlist" element={<AdoptionList />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/chat" element={<UserChat />}></Route>
        </Routes>
    
    </div>
  );
}

export default App;
