import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, InputBase, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider, useMediaQuery, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getApihandler, postApihandler } from '../../Apihandler';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useNavigate } from 'react-router-dom';

const contacts = [
  { id: 1, name: 'John Doe', avatar: 'https://via.placeholder.com/150', messages: [{ text: 'Hey!', sender: 'receiver' }, { text: 'How are you?', sender: 'receiver' }] },
  { id: 2, name: 'Jane Smith', avatar: 'https://via.placeholder.com/150', messages: [{ text: 'Hi!', sender: 'receiver' }] },
  { id: 3, name: 'Mike Johnson', avatar: 'https://via.placeholder.com/150', messages: [{ text: 'Hello!', sender: 'receiver' }] }
];

const ChatLayout = () => {
  const [selectedContact, setSelectedContact] = useState(null); // Initially no contact selected
  const [inputMessage, setInputMessage] = useState("");
  const [username,setUserName]= useState("")
  const [senderId,setSenderId]= useState("")
  const [userId,setUserId]= useState("")
  const [userdata,setUserData]= useState([]);
  const [msgdata,setMsgData]= useState([]);
  const history = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(()=>{
      getUser();
    
  },[username])

  useEffect(()=>{
   if(senderId !== ""){
    getMessage();
   }
   
},[senderId])


const videoCall = async()=>{
  history(`/room/${userId}`);
}
  const getUser = async()=>{
         const res = await getApihandler(`/SearchUser/${username}`);
         console.log("res==>",res);
         if(res.users){
          setUserData(res.users);
         }
  }

  const getMessage = async()=>{
    const userId = localStorage.getItem("userId");
    setUserId(userId)
           const res = await getApihandler(`/getChat/${userId}/${senderId}`);
           console.log("res ",res);
           if(res.data){
            setMsgData(res.data);

           }
  }
const SendMessage = async()=>{

  const userId = localStorage.getItem("userId");
  const item = {
    text:inputMessage
  }
       const res = await postApihandler(`/sendText/${userId}/${senderId}`,item);
       console.log("res==>",res);
       if(res.data){
          getMessage();
          setInputMessage("");
       }
       
}
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      
      {/* {/ Sidebar for Contacts /} */}
      {(isMobile && !selectedContact) || !isMobile ? (
        <Box sx={{ width: isMobile ? '100%' : '30%', backgroundColor: '#f0f0f0', borderRight: '1px solid #ddd', overflowY: 'auto' }}>
         <input
              type="text"
              placeholder="Search user"
              className="form-control mb-3"
               onChange={(e)=>setUserName(e.target.value)}
            />
          
          <List>
            {userdata.map((contact) => (
              <React.Fragment key={contact._id}>
                <ListItem button onClick={() => {
                  setSelectedContact(contact.name);
                  setSenderId(contact._id);
                  }}>
                  <ListItemAvatar>
                    <Avatar src="" />
                  </ListItemAvatar>
                  <ListItemText primary={contact.name} secondary="Last message..." />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      ) : null}

      {/* {/ Chat Section /} */}
      {selectedContact && (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '70%' }}>
          
          {/* {/ Chat Header /} */}
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: 'white', color: 'black' }}>
            {isMobile && (
              <IconButton color="inherit" onClick={() => setSelectedContact(null)}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Avatar src="" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6">{selectedContact}</Typography>
              <Typography variant="body2">Online</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <VideocamIcon onClick={videoCall} sx={{fontSize:"40px", color:"#b7b7b7"}}/>
          </Box>

          {/* {/ Chat Body /} */}
          <Box
            sx={{
              flexGrow: 1,
              padding: '10px',
              overflowY: 'auto',
              backgroundColor: '#e5ddd5',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {msgdata.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: message.senderId === userId ? 'flex-end' : 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '60%',
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: message.senderId === userId ? '#dcf8c6' : '#fff',
                    boxShadow: '0px 2px 2px rgba(0,0,0,0.1)',
                  }}
                >
                  <Typography variant="body1">{message.text}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* {/ Chat Footer /} */}
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f0f0f0' }}>
          
            <InputBase
              sx={{ flexGrow: 1, backgroundColor: '#fff', padding: '5px 10px', borderRadius: '20px', marginRight: '10px' }}
              placeholder="Type a message"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
             
            />
            <IconButton >
              <SendIcon onClick={SendMessage}/>
            </IconButton>
            
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatLayout;
