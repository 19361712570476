/** @format */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dog1 from "../../Images/dog1.png";
import Dog2 from "../../Images/dog2.png";
import Dog3 from "../../Images/dog3.png";
import Dog4 from "../../Images/dog4.png";
import Dog5 from "../../Images/dog5.png";
import Dog6 from "../../Images/dog6.png";
import { Box, Button, Rating } from "@mui/material";
import settingsicon from "../../Images/settings.png";
import Calendaricon from "../../Images/calendar.png";
import { getApihandler } from "../../Apihandler";
import { Link } from "react-router-dom";
export default function Card() {
  const [value, setValue] = React.useState(2);
  const [data, setData] = useState([]);
  console.log("pet profile data is---->", data);
  const getPetProfile = async () => {
    const res = await getApihandler("/getAllPetProfile");
    setData(res.data);
    console.log("get pet profile api response is ---->", res);
  };

  useEffect(() => {
    getPetProfile();
  }, []); // Adding empty dependency array

  return (
    <>
      <Container style={{ marginTop: "70px" }}>
        <h3 style={{ textAlign: "center" }}>Meet Our Pets</h3>
        <h6 style={{ textAlign: "center", color: "#f6ab4a" }}>
          Detailed Profiles to Help You Find the Perfect Match
        </h6>
        <p style={{ textAlign: "center", color: "rgb(155, 152, 152)" }}>
          Each pet has a unique personality, story, and set of needs. Our
          detailed profiles provide everything you need to know to make an
          informed decision. From photos capturing their best moments to health
          information and vaccination status, we ensure full transparency.
          Explore the descriptions, learn about their behavior, and find the
          perfect companion for your lifestyle. Your future pet is waiting to
          meet you!
        </p>
        <Row>
          {data && Array.isArray(data) && data.length > 0 ? ( // Check if data exists and is an array
            data.map((pet, index) => (
              <Col md={4} key={index}>
                <Link
                  to={`/pet/${pet._id}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div style={{ position: "relative" }}></div>
                  <Box
                    sx={{
                      boxShadow: "0px 0px 2px 1px #00000040",
                      padding: "30px",
                      borderRadius: "20px",
                      backgroundColor: "white",
                      height: "100%",
                    }}
                  >
                    <img
                      src={"http://localhost:80/" + pet.photos[0]}
                      width="100%"
                      alt="Pet"
                    />
                    <h4 style={{ textAlign: "center" }}>{pet.name}</h4>
                    <div>
                      <img src={settingsicon} alt="Breed" />
                      <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                        {pet.breed}
                      </span>
                      <img
                        src={Calendaricon}
                        alt="Birth Year"
                        style={{ marginLeft: "10px" }}
                      />
                      <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                        Birth: {pet.age}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <Rating
                        name={`rating-${index}`}
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                      <h6 style={{ color: "#9b9898" }}>
                        Adoption Fee:{" "}
                        <span style={{ color: "black" }}>
                          {pet.adoptionFee || "Free"}
                        </span>
                      </h6>
                    </div>
                  </Box>
                </Link>
              </Col>
            ))
          ) : (
            <p>No pets available</p> // Fallback in case data is not available
          )}
        </Row>
        <div className="text-center mt-5">
          <Link to="/findpet">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#f6ab4a", borderRadius: "12px" }}
            >
              Find Pet
            </Button>
          </Link>
        </div>
        {/* <Row style={{ marginTop: "180px" }}>
          <Col md={4}>
            <div style={{ position: "relative" }}>
              <img src={Dog4} alt="" width="100%" />
            </div>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",

                borderRadius: "20px",
                position: "absolute",
                // left: "10%",
                marginTop: "-45px",
                backgroundColor: "white",
              }}
            >
              <h4 style={{ textAlign: "center" }}>Learn More</h4>
              <div>
                <img src={settingsicon} alt="" />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  German sharped
                </span>
                <img src={Calendaricon} alt="" style={{ marginLeft: "10px" }} />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  Birth : 2021
                </span>
              </div>
              <div className="d-flex justify-content-between mt-5">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <h6 style={{ color: "#9b9898" }}>
                  Total Price : <span style={{ color: "black" }}>Free</span>
                </h6>
              </div>
            </Box>
          </Col>
          <Col md={4}>
            <div style={{ position: "relative" }}>
              <img src={Dog5} alt="" width="100%" />
            </div>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",

                borderRadius: "20px",
                position: "absolute",
                // left: "10%",
                marginTop: "-45px",
                backgroundColor: "white",
              }}
            >
              <h4 style={{ textAlign: "center" }}>Learn More</h4>
              <div>
                <img src={settingsicon} alt="" />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  German sharped
                </span>
                <img src={Calendaricon} alt="" style={{ marginLeft: "10px" }} />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  Birth : 2021
                </span>
              </div>
              <div className="d-flex justify-content-between mt-5">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <h6 style={{ color: "#9b9898" }}>
                  Total Price : <span style={{ color: "black" }}>Free</span>
                </h6>
              </div>
            </Box>
          </Col>
          <Col md={4}>
            <div style={{ position: "relative" }}>
              <img src={Dog6} alt="" width="100%" />
            </div>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",

                borderRadius: "20px",
                position: "absolute",
                // left: "10%",
                marginTop: "-45px",
                backgroundColor: "white",
              }}
            >
              <h4 style={{ textAlign: "center" }}>Learn More</h4>
              <div>
                <img src={settingsicon} alt="" />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  German sharped
                </span>
                <img src={Calendaricon} alt="" style={{ marginLeft: "10px" }} />
                <span style={{ color: "#9b9898", marginLeft: "10px" }}>
                  Birth : 2021
                </span>
              </div>
              <div className="d-flex justify-content-between mt-5">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
                <h6 style={{ color: "#9b9898" }}>
                  Total Price : <span style={{ color: "black" }}>Free</span>
                </h6>
              </div>
            </Box>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}
