/** @format */
import React from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import usericon from "../../Images/usericon.png";
import ChatIcon from "@mui/icons-material/Chat";
// import NotificationToast from "./NotificationToast";
// import "./Header.css"; // External CSS for better responsive control

export default function Header() {
  return (
    <>
      {/* <NotificationToast /> */}
      <Navbar expand="lg" className="header-navbar">
        <Container>
          <Link
            to="/home" className="nav-link"
           
            style={{ color: "white" ,textDecoration:"none"}}
          >
            LOGO
          </Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="header-toggle"
            style={{ backgroundColor: "white" }}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="header-nav">
              <Link to="/home" className="nav-link">
                Home
              </Link>
              <Link to="/adoptionlist" className="nav-link">
                Adoption List
              </Link>
              <Link to="/aboutus" className="nav-link">
                About Us
              </Link>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>

          <div className="header-icons">
            <Link to="/myprofile" className="profile-link">
              <img src={usericon} alt="User" className="header-user-icon" />
            </Link>
            <Link to="/chat" className="chat-link">
              <ChatIcon sx={{ color: "white", marginLeft: "10px" }} />
            </Link>
          </div>
        </Container>
      </Navbar>
    </>
  );
}
