/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getApihandler,
  postApihandler,
  postWithoutAccessApihandler,
} from "../../Apihandler";
import Header from "../../Components/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Button } from "@mui/material";
import Footer from "../../Components/Footer";
import Swal from "sweetalert2";

export default function PetProfile() {
  const { petId } = useParams();
  const [data, setData] = useState([]);
  const [adoptionData, setAdoptionData] = useState(null);
  console.log("get data for booked pet profile", adoptionData);

  // ****** api for get pets by petid********
  const getPetProfileId = async () => {
    const res = await getApihandler(`/getPetProfileById/${petId}`);
    setData(res.data);
    // console.log("get pet profile by id----->", res);
  };
  useEffect(() => {
    getPetProfileId();
    getBookPet();
  }, []);

  // ******** api is for booked pet *******
  const handleBookpet = async () => {
    const userId = localStorage.getItem("userId");
    const data = {
      userId: userId,
      petId: petId,
    };

    // console.log("book pet data is ----->", data);

    try {
      const res = await postApihandler("/adoptPet", data);

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Successfully adopted the pet",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to adopt the pet",
          text: res.error.response.data.message || "Something went wrong!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      // Checking for specific error when the pet is already adopted
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message.includes("already been adopted")
      ) {
        Swal.fire({
          icon: "error",
          title: "Pet Already Adopted",
          text: "This pet has already been adopted by someone else.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        // Handle other errors
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "Something went wrong!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  // ****** api is for get user who book the pet based on petId ********
  const getBookPet = async () => {
    const res = await getApihandler(`/getUserDataByPetId/${petId}`);
    if (res && res.adoptionData && res.adoptionData.length > 0) {
      setAdoptionData(res.adoptionData[0]); // Assuming you get an array of adoption data
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-5">
        <h3 className="text-center">{data.breed}</h3>
        <Row className="mt-5">
          <Col md={6}>
            {/* Mapping over the photos array and displaying each image */}
            <div style={{ position: "relative" }}>
              <Row>
                {data.photos && data.photos.length > 0 ? (
                  data.photos.map((photo, index) => (
                    <Col md={6}>
                      <img
                        key={index}
                        src={`http://localhost:80/${photo.replace(/\\/g, "/")}`} // Replace backslashes with forward slashes
                        width="100%"
                        alt={`Pet image ${index + 1}`}
                        style={{ marginBottom: "10px" }} // Optional margin
                      />
                    </Col>
                  ))
                ) : (
                  <p>No photos available</p>
                )}
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            >
              <h4 style={{ textAlign: "center" }}>{data.breed}</h4>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Name:
                  <span style={{ color: "black" }}>{data.name}</span>
                </h6>
              </div>
              <div>
                {/* <img src={settingsicon} alt="Breed" /> */}

                {/* <img
                  src={Calendaricon}
                  alt="Birth Year"
                  style={{ marginLeft: "10px" }}
                /> */}
                <div className="d-flex justify-content-between mt-2">
                  <h6 style={{ color: "#9b9898" }}>
                    Birth:
                    <span style={{ color: "black" }}>{data.age}</span>
                  </h6>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Color :<span style={{ color: "black" }}>{data.color}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Gender :<span style={{ color: "black" }}>{data.gender}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Species :
                  <span style={{ color: "black" }}>{data.species}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Size :<span style={{ color: "black" }}>{data.size}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Adoption Fee:{" "}
                  <span style={{ color: "black" }}>
                    {data.adoptionFee || "Free"}
                  </span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Vaccine Status :
                  <span style={{ color: "black" }}>{data.vaccineStatus}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Location :
                  <span style={{ color: "black" }}>{data.location}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Phone :<span style={{ color: "black" }}>{data.phone}</span>
                </h6>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <h6 style={{ color: "#9b9898" }}>
                  Description:
                  <span style={{ color: "black" }}>{data.description}</span>
                </h6>
              </div>
              {/* Conditionally render based on whether the pet is already adopted */}
              {adoptionData ? (
                <Box mt={3} p={2} sx={{ backgroundColor: "#f9f9f9" }}>
                  <h6>Pet Already Adopted</h6>
                  <p>
                    <strong>Adopted by:</strong> {adoptionData.userName} (
                    {adoptionData.userEmail})
                  </p>
                  <p>
                    <strong>Adoption Status:</strong> {adoptionData.status}
                  </p>
                  <p>
                    <strong>Adoption Date:</strong>{" "}
                    {new Date(adoptionData.adoptionDate).toLocaleDateString()}
                  </p>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#f6ab4a", marginTop: "30px" }}
                  onClick={handleBookpet}
                >
                  Book Pet
                </Button>
              )}
            </Box>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
