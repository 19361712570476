/** @format */

import React, { useEffect } from "react";
import HomePage from "../../Components/HomePage/HomePage"; // Check for case sensitivity
import Header from "../../Components/Header";
import NextSection from "../../Components/HomePage/nextsection";
import Counter from "../../Components/HomePage/counter";
import Card from "../../Components/HomePage/card";
import Blogs from "../../Components/HomePage/blogs";
import ChooseUs from "../../Components/HomePage/chooseus";
import Footer from "../../Components/Footer";
import GetVideo from "../../Components/HomePage/getvideo";
// import { generateToken } from "../../firebase";
export default function Home() {

  // useEffect lifecycle
  // useEffect(()=>{
  //   generateToken();
  // },[])
  return (
    <>
      <Header />
      <HomePage />
      {/* <Filter /> */}
      {/* <FindPet /> */}
      <NextSection />

      <Counter />
      <Card />
      <GetVideo />
      <Blogs />
      <ChooseUs />
      <Footer />
    </>
  );
}
