/** @format */

import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { getApihandler } from "../../Apihandler";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Box, Button, Card, Rating } from "@mui/material";

export default function FindPetFilter() {
  const [value, setValue] = React.useState(2);
  const [data, setData] = useState([]);
  // console.log("pet profile data is---->", data);
  // ***** all get data *******
  useEffect(() => {
    getPetProfile();
  }, []);
  const getPetProfile = async () => {
    const res = await getApihandler("/getAllPetProfile");
    setData(res.data);
    console.log("get pet profile api response is ---->", res);
  };

  // ****** filter api *******
  const [petname, setPetName] = useState("");
  // console.log("pet name is ---->", petname);
  const [species, setSpecies] = useState("");
  const [breed, setBreed] = useState("");
  const [age, setAge] = useState("");
  const [color, setColor] = useState("");
  const [gender, setGender] = useState("");
  console.log("gender is ", gender);
  const [size, setSize] = useState("");
  console.log("size is ", size);

  const [vaccineStatus, setVaccineStatus] = useState("");
  console.log("vaccineStatus is ", vaccineStatus);

  const [location, setLocation] = useState("");
  const [minage, setMinAge] = useState("");
  const [maxage, setMaxAge] = useState("");
  const [minadoptionfee, setMinAdoptionFee] = useState("");
  const [maxadoptionfee, setMaxAdoptionFee] = useState("");

  const handleSubmit = async () => {
    const res = await getApihandler(
      `/searchPets?gender=${gender}&size=${size}&vaccineStatus=${vaccineStatus}&name=${petname}&species=${species}&breed=${breed}&age=${age}&color=${color}&location=${location}&minAge=${minage}&maxAge=${maxage}&minAdoptionFee=${minadoptionfee}&maxAdoptionFee=${maxadoptionfee}`
    );
    console.log("filter api response is --->", res);
    if (res.length !== 0) {
      setData(res);
    } else {
      setData([]);
    }
  };
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col md={3}>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",
                marginTop: "50px",
              }}
            >
              <h4 style={{ color: "rgb(246, 171, 74)" }}>Apply Filter</h4>
              <form>
                <h6>Name</h6>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  // value={filters.name}
                  className="form-control mb-3"
                  onChange={(e) => setPetName(e.target.value)}
                />
                <h6>Species</h6>
                <input
                  type="text"
                  name="species"
                  placeholder="Species"
                  // value={filters.species}
                  className="form-control mb-3"
                  onChange={(e) => setSpecies(e.target.value)}
                />
                <h6>Breed</h6>

                <input
                  type="text"
                  name="breed"
                  placeholder="Breed"
                  // value={filters.breed}
                  className="form-control mb-3"
                  onChange={(e) => setBreed(e.target.value)}
                />
                <h6>Age</h6>

                <input
                  type="number"
                  name="age"
                  placeholder="Age"
                  // value={filters.age}
                  className="form-control mb-3"
                  onChange={(e) => setAge(e.target.value)}
                />
                <h6>Gender</h6>

                <select
                  name="gender"
                  // value={filters.gender}
                  className="form-control mb-3"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <h6>Size</h6>

                <select
                  name="size"
                  // value={filters.size}
                  className="form-control mb-3"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <option value="">Size</option>
                  <option value="Small">Small</option>
                  <option value="Medium">Medium</option>
                  <option value="Large">Large</option>
                </select>
                <h6>Color</h6>

                <input
                  type="text"
                  name="color"
                  placeholder="Color"
                  // value={filters.color}
                  className="form-control mb-3"
                  onChange={(e) => setColor(e.target.value)}
                />
                <h6>Vaccine Status</h6>

                <select
                  name="vaccineStatus"
                  // value={filters.vaccineStatus}
                  className="form-control mb-3"
                  onChange={(e) => setVaccineStatus(e.target.value)}
                >
                  <option value="">Vaccine Status</option>
                  <option value="Vaccinated">Vaccinated</option>
                  <option value="Not Vaccinated">Not Vaccinated</option>
                </select>
                <h6>Location</h6>

                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  // value={filters.location}
                  className="form-control mb-3"
                  onChange={(e) => setLocation(e.target.value)}
                />
                <h6>Min Age</h6>

                <input
                  type="number"
                  name="minAge"
                  placeholder="Min Age"
                  // value={filters.minAge}
                  className="form-control mb-3"
                  onChange={(e) => setMinAge(e.target.value)}
                />
                <h6>Max Age</h6>

                <input
                  type="number"
                  name="maxAge"
                  placeholder="Max Age"
                  // value={filters.maxAge}
                  className="form-control mb-3"
                  onChange={(e) => setMaxAge(e.target.value)}
                />
                <h6> Min Adoption Fee</h6>

                <input
                  type="number"
                  name="minAdoptionFee"
                  placeholder="Min Adoption Fee"
                  className="form-control mb-3"
                  // value={filters.minAdoptionFee}
                  onChange={(e) => setMinAdoptionFee(e.target.value)}
                />
                <h6> Max Adoption Fee</h6>

                <input
                  type="number"
                  name="maxAdoptionFee"
                  placeholder="Max Adoption Fee"
                  className="form-control mb-3"
                  // value={filters.maxAdoptionFee}
                  onChange={(e) => setMaxAdoptionFee(e.target.value)}
                />
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "rgb(246, 171, 74)" }}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </form>
            </Box>
          </Col>
          <Col md={9} className="mt-5">
            <Row>
              {data && Array.isArray(data) && data.length > 0 ? ( // Check if data exists and is an array
                data.map((pet, index) => (
                  <Col md={4} key={index}>
                    <Card>
                      <Link
                        to={`/pet/${pet._id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div style={{ position: "relative" }}>
                          <img
                            src={"http://localhost:80/" + pet.photos[0]}
                            width="100%"
                            alt="Pet"
                          />
                        </div>
                        <Box
                          sx={{
                            // boxShadow: "0px 0px 2px 1px #00000040",
                            padding: "30px",
                            borderRadius: "20px",
                            backgroundColor: "white",
                          }}
                        >
                          <h4 style={{ textAlign: "center" }}>{pet.breed}</h4>

                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Name:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.name}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Size:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.size}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Birth:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.age}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Adoption Fee:{" "}
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.adoptionFee || "Free"}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Color:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.color}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              Species:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.species}
                              </span>
                            </span>
                          </div>
                          <div>
                            <span
                              style={{
                                color: "#000000",
                                fontWeight: "600",
                              }}
                            >
                              VaccineStatus:
                              <span
                                style={{
                                  color: "#9b9898",
                                  marginLeft: "30px",
                                }}
                              >
                                {pet.vaccineStatus}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between mt-5">
                            <Rating
                              name={`rating-${index}`}
                              value={value}
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                            />
                          </div>
                        </Box>
                      </Link>
                    </Card>
                  </Col>
                ))
              ) : (
                <p>No pets available</p> // Fallback in case data is not available
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
