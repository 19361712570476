/** @format */

import React, { useEffect, useState } from "react";
import {
  deleteApiihandler,
  getApihandler,
  putApihandler,
} from "../../Apihandler";
import { Box, Button, Modal, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header";

export default function MyProfile() {
  // ***** modal style ********
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState("");

  const Userprofile = async () => {
    try {
      // Get the userId from localStorage or another source
      const userId = localStorage.getItem("userId");

      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      // Get the access token from localStorage or another source (update if you store it elsewhere)

      // Send the API request with the userId and access token in the header
      const res = await getApihandler(`/getUserById/${userId}`);
      setData(res.data);
      console.log(" get API response is ---->", res);

      // Handle your response here
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    Userprofile();
  }, []);
  // ******* update api *********
  const [name, setName] = useState(""); // State to store updated name
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("rupali");
    const userId = localStorage.getItem("userId");
    const res = await putApihandler(`/updateUserProfile/${userId}`, {
      name, // Send updated name
      phoneNumber, // Send updated phone number
    });
    console.log("update api response is --->", res);
    // alert("Profile updated successfully!");
    setData(res.data);
  };

  // ****** delete user api ********
  const handleDeleteProfile = async () => {
    const userId = localStorage.getItem("userId");
    console.log("user id is----->", userId);
    const res = await deleteApiihandler(`/deleteUser/${userId}`);
    console.log("delete api response is ---->", res);
  };
  return (
    <>
      <Header />
      <Typography
        variant="h4"
        sx={{ textAlign: "center", marginTop: "40px", fontWeight: "600" }}
      >
        {" "}
        My <span style={{ color: "#f6ab4a", fontWeight: "600" }}>Profile</span>
      </Typography>
      <Container>
        <Row>
          <Col md={3}></Col>
          <Col md={9}>
            <Box
              sx={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "30px",
                marginTop: "50px",
              }}
            >
              <Row>
                <Typography variant="h6" sx={{ fontWeight: "500" }}>
                  {" "}
                  Account
                </Typography>
                <Col md={6} style={{ fontWeight: "600" }}>
                  Name
                </Col>
                <Col md={6}>{data.name}</Col>
                <Col md={6} style={{ fontWeight: "600" }}>
                  Email
                </Col>
                <Col md={6}>{data.userEmail}</Col>
                <Col md={6} style={{ fontWeight: "600" }}>
                  Phone Number
                </Col>
                <Col md={6}>{data.phoneNumber}</Col>
              </Row>
              <Button
                onClick={handleOpen}
                sx={{
                  backgroundColor: "#f6ab4a",
                  color: "white",
                  marginTop: "30px",
                }}
              >
                Edit Profile
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#f6ab4a",
                  color: "white",
                  marginTop: "30px",
                  marginLeft: "20px",
                }}
                onClick={handleDeleteProfile}
              >
                Delete Profile
              </Button>
              {/* ******* modal box ******** */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <h3>Update Profile</h3>
                  <form onSubmit={handleUpdate}>
                    <div className="mb-3">
                      <label>Name:</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // Update name state
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label>Phone:</label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)} // Update phoneNumber state
                        className="form-control"
                      />
                    </div>

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: "#f6ab4a" }}
                    >
                      Edit Profile
                    </Button>
                  </form>
                </Box>
              </Modal>
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  );
}
