/** @format */

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import catdogimg from "../../Images/cat-dog-being-affectionate-showing-love-towards-each-other 1.png";
import medalimg from "../../Images/Promoted.png";
import messageicon from "../../Images/message.png";
import callicon from "../../Images/call.png";
export default function ChooseUs() {
  return (
    <>
      <section className="mt-5">
        <Container>
          <Row>
            <Col md={6}>
              <img src={catdogimg} alt="" width="100%" />
            </Col>
            <Col md={6}>
              <h5 style={{ color: "#f6ab4a" }}>Why Choose Us</h5>
              <h1 style={{ color: " #482F30", fontWeight: "700" }}>
                Your Trusted Partner in Pet Adoption
              </h1>
              <p
                style={{
                  color: "#898989",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                We understand that adopting a pet is a life-changing decision,
                and we’re here to guide you every step of the way. With years of
                experience and a deep passion for animal welfare, we are
                committed to making the adoption process smooth and successful.
                Our comprehensive support, well-cared-for pets, and dedicated
                team ensure that both you and your new pet have a happy and
                fulfilling experience together. Here's why thousands of pet
                parents have trusted us:
              </p>
              <div>
                <Row>
                  <Col
                    md={2}
                    style={{
                      background: "#482F30",
                      borderRadius: "12px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={medalimg} alt="" />
                  </Col>
                  <Col md={10}>
                    <h4>Extensive Health Care </h4>
                    <p style={{ color: "#898989" }}>
                      All our pets receive full medical checkups, vaccinations,
                      and preventive care.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col
                    md={2}
                    style={{
                      background: "#482F30",
                      borderRadius: "12px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={messageicon} alt="" />
                  </Col>
                  <Col md={10}>
                    <h4>Community of Pet Lovers</h4>
                    <p style={{ color: "#898989" }}>
                      Join a growing network of pet parents who share their
                      experiences and tips.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col
                    md={2}
                    style={{
                      background: "#482F30",
                      borderRadius: "12px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={callicon} alt="" />
                  </Col>
                  <Col md={10}>
                    <h4>Dedicated Support </h4>
                    <p style={{ color: "#898989" }}>
                      We offer personalized guidance to match you with the right
                      pet.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
